<template>
  <div class="_bg-default _80w _100vh pb-3 px-3">
    <div class="_100top">
      <div class="mb-3">
        <v-btn small fab depressed color="green" dark @click="getBack">
          <v-icon>mdi-reply</v-icon>
        </v-btn>
      </div>
      <v-row v-if="agendaDetail">
        <v-col cols="12" md="6">
          <v-card class="radius-card pa-2">
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Judul Kegiatan"
                      v-model="agendaDetail.data.judul"
                      required
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea
                      label="Description"
                      hide-details
                      v-model="agendaDetail.data.deskripsi"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Lokasi"
                      required
                      v-model="agendaDetail.data.lokasi"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="calendar1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="start_evt"
                          label="Waktu Mulai"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="start_evt"
                        @input="calendar1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="calendar2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="end_evt"
                          label="Waktu Berakhir"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="end_evt"
                        @input="calendar2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      :items="listType"
                      dense
                      v-model="agendaDetail.data.tipe_agenda"
                      hide-details="auto"
                      label="Tipe Event"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="agendaDetail.data.tipe_agenda == 'berbayar'">
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Harga"
                      required
                      v-model="agendaDetail.data.biaya"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Fasilitas"
                      required
                      v-model="agendaDetail.data.fasilitas"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Pemateri"
                      required
                      v-model="agendaDetail.data.pemateri"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Kuota"
                      type="number"
                      required
                      v-model="agendaDetail.data.kuota"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Norek"
                      required
                      v-model="agendaDetail.data.no_rek"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>

          <v-card class="radius-card mt-3 __relative" height="300">
            <div class="__template__cover">
              <img :src="dp" class="cover__" alt="img" v-if="dp" />
            </div>
            <div class="d-flex justify-center mt-4">
              <v-btn rounded outlined color="green">
                <div class="upload-btn-wrapper add-width">
                  <div class="pa-1">
                    <v-icon>mdi-upload</v-icon> Pilih Poster
                    <input type="file" name="myfile" @change="getPic($event)" />
                  </div>
                </div>
              </v-btn>
            </div>
          </v-card>

          <h3 class="mt-3">Categories:</h3>
          <v-card class="radius-card mt-3 pa-3" :color="bgColor" height="auto">
            <v-radio-group v-model="agendaDetail.data.nama_kategori">
              <v-radio
                label="Psikologi Klinis"
                value="Psikologi Klinis"
                @change="bgColor = 'a__'"
              ></v-radio>
              <v-radio
                label="Psikologi Pendidikan"
                value="Psikologi Pendidikan"
                @change="bgColor = 'b__'"
              ></v-radio>
              <v-radio
                label="PIO"
                value="Psikologi Industri & Organisasi"
                @change="bgColor = 'c__'"
              ></v-radio>
            </v-radio-group>
          </v-card>
          <footer class="d-flex justify-end mt-5">
            <v-btn dark color="green" @click="updateAgenda(agendaDetail)"
              >SAVE</v-btn
            >
          </footer>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="radius-card __overflow__hidden">
            <v-sheet height="auto">
              <v-toolbar color="grey lighten-5" flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                  small
                >
                  Today
                </v-btn>
                <v-btn fab text x-small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text x-small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <small v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </small>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="auto">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
              ></v-calendar>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="pa-3" v-if="dataAgenda">
      <ViewAgenda
        v-bind:data="dataAgenda"
        v-bind:dialog="dialogAgenda"
        @close="closeDialog"
        @fetch="fetchItem"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import ViewAgenda from "../../components/Admin/Modal/viewAgenda.vue";

export default {
  name: "editAgenda",
  components: {
    ViewAgenda
  },
  data() {
    return {
      start_evt: null,
      end_evt: null,
      calendar1: false,
      calendar2: false,
      category: "",
      cover: null,
      dp: null,
      bgColor: "",
      focus: "",
      type: "month",
      listType: ["berbayar", "gratis"],
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days"
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dialogAgenda: false,
      dataAgenda: null
    };
  },
  computed: {
    ...mapState({
      agenda: state => state.agenda.agenda,
      agendaDetail: state => state.agenda.agendaDetail,
      env: state => state.API_URL
    }),
    events() {
      let evt = [];
      if (this.agenda) {
        let agenda = this.agenda.data;
        for (let i = 0; i < agenda.length; i++) {
          evt.push({
            id: agenda[i].id,
            name: agenda[i].judul,
            start: agenda[i].start_date,
            end: agenda[i].end_date,
            timed: null,
            color:
              agenda[i].nama_kategori == "Psikologi Klinis"
                ? "a__"
                : agenda[i].nama_kategori == "Psikologi Pendidikan"
                ? "b__"
                : agenda[i].nama_kategori == "PIO"
                ? "b__"
                : "red"
          });
        }
      }
      return evt;
    }
  },
  mounted() {
    this.fetch();
    this.fetchDetail();
  },
  methods: {
    getBack() {
      this.$router.go(-1);
    },
    fetch() {
      this.$store.dispatch("agenda/listAgenda");
    },
    fetchDetail() {
      let id = this.$route.params.id;
      this.$store.dispatch("agenda/AgendaById", id).then(res => {
        this.start_evt = this.$date(res.data.data.start_date).format(
          "YYYY-MM-DD"
        );
        this.end_evt = this.$date(res.data.data.end_date).format("YYYY-MM-DD");
        this.dp = `${this.env}/agenda/${res.data.data.poster}`;
      });
    },

    // set up caelndar
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.focus = "";
    },

    viewAgenda(data) {
      this.dialogAgenda = true;
      this.dataAgenda = data;
      console.log("agus cabul");
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    getPic(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = f => {
        this.dp = f.target.result;
      };
      fr.readAsDataURL(file);
      this.cover = file;
    },

    updateAgenda(data) {
      this.$store.dispatch("agenda/updateAgenda", data).then(data => {
        if (data.data.status == "success") {
          Swal.fire({
            icon: "success",
            title: data.data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
          this.$router.push(`/admin/agenda-list`);
        } else {
          Swal.fire({
            icon: "error",
            title: data.data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        }
      });
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedElement = nativeEvent.target;
        this.idTask = parseInt(event.id);
        this.viewAgenda(event);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    closeDialog() {
      this.dataAgenda = null;
      this.dialogAgenda = false;
    }
  }
};
</script>

<style scoped>
.__overflow__hidden {
  overflow: hidden !important;
  box-sizing: border-box;
}
.__template__cover {
  width: 100%;
  height: 230px;
  background: #f3e5f5;
}
.__relative {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.cover__ {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
